<template>
  <div>
    <transition
      name="slide"
      mode="out-in"
      :duration="700"
    >
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Society'
}
</script>

<style lang="scss" scoped>
.slide-leave-active,
.slide-enter-active {
  transition: 0.7s;
}
.slide-enter {
  transform: translate(0, 100%);
}
.slide-leave-to {
  transform: translate(0, -100%);
}
</style>